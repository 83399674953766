import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Content } from '../../../components/287634/content';

const TermsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { regex: "/content/terms/" }) {
        html
      }
    }
  `);
  return <Content content={data.markdownRemark.html} />;
};

export default TermsPage;
